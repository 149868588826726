<template>
  <div  class="content ma-0 mb-16 px-0 px-sm-16  mx-sm-12 pb-12" >

    <div class="mx-1 mt-12 mb-8  px-md-14 mx-md-14" v-if="iteminfo.headimage!=''">
      <v-img :src="imgdomain+iteminfo.headimage" ></v-img>
    </div>

    <div class="text-h5 text-md-h4 font-weight-bold text-center mt-md-6 pt-6 pb-8" v-if="iteminfo.uid==0">
      <div style="position: relative;">{{iteminfo.title}}
        <Share />
      </div>
      <div class="grey--text text--darken-1 py-2 text-h6">{{iteminfo.subtitle}}</div>
    </div>

    


    <div class="mx-1 my-0 py-0 mt-0 topicitem px-md-14 mx-md-14" v-for="item in iteminfo.content" :key="item.topic_id">
      <div v-if="item.type=='content'">

        <v-row :class="{'flex-row-reverse':item.imgpostion}">

          <v-col cols="12" class="d-flex justify-center pa-0" v-if="item.video" :md="item.video&&item.text!=''?6:12">
            <video controls width="100%" :src="imgdomain+item.video" v-if="item.video" ></video>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pa-0" v-if="item.images.length>0 && !item.video" :md="item.images.length>0&&item.text!=''?6:12">
            <!--<v-img :src="imgdomain+item.images[0].file" v-if="item.images.length==1" :max-width="Math.min(650,item.images[0].width)"></v-img>-->
            
            <v-img 
              :src="imgdomain+item.images[0].file" 
              v-if="item.images.length==1"
              >
            </v-img>

            <v-carousel v-if="item.images.length>1" show-arrows-on-hover height="460px" delimiter-icon="mdi-minus" cycle hide-delimiter-background light>
              <v-carousel-item v-for="img in item.images" :key="img.id" >
                  <v-img  :src="imgdomain+img.file" max-height=460 contain></v-img> 
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col  style="position: relative" class="d-flex flex-column align-center pa-0 justify-center" :class="{'justify-center':item.images.length==0&&!item.video}" cols="12" :md="(item.images.length>0||item.video)&&item.text!=''?6:12">
            <Share  v-if="iteminfo.uid>0" />
            <div v-if="item.title!=''" class="text-h5 pa-4 text-center">{{item.title}}</div>
            <div class="grey--text text-center pb-2">{{item.subtitle}}</div>
            <div v-if="item.text!=''" class="text-center px-8 px-md-14 mx-md-4 my-2">
              {{item.text}}
              
            </div>
          </v-col>
        </v-row>
        
        <div v-if="iteminfo.uid>0">
          <div class="d-flex username mb-6" @click="$router.push('/kol?uid='+iteminfo.uid)">

            <v-avatar class="ml-4 mt-1"  size="18" v-if="iteminfo.userinfo.user_headimg">
              <v-img :src="imgdomain+iteminfo.userinfo.user_headimg"></v-img>
            </v-avatar>
            <div class="pl-1">{{iteminfo.userinfo.nick_name}}</div>

          </div>
        </div>

      </div>

      <div v-if="item.type=='goods'">
        <!--<v-item-group active-class="primary">
        <v-row class="justify-center">
          <v-col v-for="goods in item.list" :key="goods.goods_id" cols="6" md="4">
            <Item :goods="goods" />
          </v-col>
        </v-row>
        </v-item-group>-->

        <v-carousel show-arrows-on-hover height="550" delimiter-icon="mdi-minus"  hide-delimiter-background light>
          <v-carousel-item v-for="goods in item.list" :key="goods.goods_id" >
            <v-card flat :ripple=false class="align-center goods-item pa-0 pointer" @click="goGoodsDetail(goods)">
              <v-img :src="imgdomain+goods.img" max-height=400 contain></v-img>
              <div class="font-weight-black text-center text-subtitle-1 mb-0 mt-4">
                {{goods.brand_name}}
              </div>
              <div class="font-weight-regular text-center  text-caption text-md-subtitle-2 mb-2">
                {{goods.goods_name}}
              </div>
              <div class="font-weight-regular text-center  text-body-2 my-2">
                ¥{{goods.price}}
              </div>     
            </v-card>     
          </v-carousel-item>
        </v-carousel>

      </div>
    </div>

  <div v-if="iteminfo.historyImags && iteminfo.historyImags.length>0" class="d-flex " style="overflow-x:scroll">
    <v-img :src="imgdomain+img" v-for="img in iteminfo.historyImags" :key="img" height="325" ></v-img>
  </div>

  <div v-if="iteminfo.uid>0">
    <div class="d-flex username mb-6" @click="$router.push('/kol?uid='+iteminfo.uid)">

      <v-avatar class="ml-4 mt-1"  size="18" v-if="iteminfo.userinfo.user_headimg">
        <v-img :src="imgdomain+iteminfo.userinfo.user_headimg"></v-img>
      </v-avatar>
      <div class="pl-1">{{iteminfo.userinfo.nick_name}}</div>

    </div>
  </div>



    <div class="moretitle mt-8 mt-md-12 mb-md-12 pb-1 pl-1 font-weight-bold">MORE 查看更多</div>
    <v-sheet fluid class="section-4 pt-4 mb-14 pb-12">
      <v-row >
        <v-col class="mt-0" cols="6" md="3" v-for="item in topics" :key="item.topic_id" style="height:350px">
          <div class="topic-item pointer" v-if="item.uid==0" @click="$router.push('/topicdetail?id='+item.topic_id)">
            <!--<div class="topic-item d-flex flex-column justify-space-between" v-if="true" >-->
              <div class="top-img">
                <v-img :src="imgdomain+item.image" class="white--text align-end" height="325">
                  <div class="top-title topic-title pl-4 ">{{item.title}}</div>
                  <div class="top-subtitle  pl-4 mb-4">{{item.subtitle}}</div>
                </v-img>
              </div>
            
            
          </div>
          <div class="topic-item d-flex grey lighten-3 flex-column justify-space-between" v-else>
            <div class="top-img">
              <v-img :src="imgdomain+item.image" max-height="194" :aspect-ratio="1/1"></v-img>
            </div>
            <div class="top-title topic-title px-3 py-0 pa-md-3 text-center" >{{item.title}}</div>
            <div class="top-subtitle grey--text text--darken-2 text-center pb-0">{{item.nick_name}}</div>
            <div class="top-btn text-center showdetailbtn">
              <v-btn  light plain   tile class="" @click="$router.push('/topicdetail?id='+item.topic_id)">查看详情</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>



  </div>
</template>

<script>

import topicApi from '@/api/topic'
import { mapState } from 'vuex'

import Share from '@/components/Share'

export default {
  components: {
    Share
  },

  data: () => ({
      shareImg : '',
      itemid:0,
      iteminfo:{},
      topics:[]

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
      imgdomain: state => state.app.imgdomain,
      apidomain: state => state.app.apidomain,
      uid: state => state.user.uid,
    }),
    
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if(this.$route.query.id) {
          this.init()
          window.scrollTo(0,0);
        }
      }
    }
  },
  created() {

    

  },
  methods: {
    homesearch() {
      alert(3)
    },
    init() {
      let itemid = this.$route.query.id
      itemid = parseInt(itemid)

      this.itemid = itemid
      let params = {id:itemid}
      topicApi.topicdetail(params).then(res => {
        this.iteminfo = res.data

        if (this.iteminfo.uid>0) {
          this.iteminfo.content[0].title = this.iteminfo.title
        }

        this.$util.setTitle(this.iteminfo.title)
        let shareimg = this.domain + 'logo_icon_big.png'
        if (this.iteminfo.image!=""){
          shareimg = this.imgdomain + this.iteminfo.image
        }
        this.$util.weixinShare(window.location.href,document.title,shareimg)

      })
      
      this.shareImg = this.apidomain + "qrcode.php?code=" + encodeURIComponent(location.href)

      topicApi.hometopic().then(res => {
        this.topics = res.data
      })
    },
    goGoodsDetail(goods) {
      let p = {id:goods.goods_id}
      if (this.iteminfo.uid>0) {
        //p.source_uid = this.iteminfo.uid
        let source_uid = this.iteminfo.uid
        if (source_uid>0 && source_uid!=this.uid) {
          this.$util.setSourceId(source_uid)
        }
      }
      

      this.$router.push({path:'/itemdetail',query:p})
    }

  },
  
};
</script>
<style scoped>
.content {

  position: relative;
}  

.moretitle {
  font-size:1.1rem;
  border-bottom:1px solid black;
}

.username{
  margin-top:25px;
  height: 36px;
  background:black;
  color:white;
  position: relative;
  font-size:18px;
  font-weight: 600;
  padding-top:5px;
  padding-left:8px;
  width:fit-content;
  padding-right:8px;
  cursor: pointer;
}

.username::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    border: 8px solid #fff;
    border-top-color: transparent;
    border-right-color: transparent;
}
.username::after{
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    border: 8px solid #fff;
    border-bottom-color: transparent;
    border-right-color: transparent;
}
</style>
